/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { navigate } from 'gatsby';

// Ui lib components
import { Container } from 'react-grid-system';

// Helpers
import { productQualifierByCategory } from '../../Helpers/entities';

// Context
import { DateContext } from '../../../context/DateContext';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                          Single Evening Card Component                     */
/* -------------------------------------------------------------------------- */

function EventCard({ id, title, category, image, date, location }) {
  // Localization
  const { t } = useTranslation('Common');
  const context = useContext(DateContext);
  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="event-card fluid">
      <div
        className="category-img"
        loading="lazy"
        style={{ backgroundImage: `url(${image})` }}
      />
      <Container className="details flex flex-column fluid">
        <p className="title">{title}</p>
        {/* eslint-disable indent */}
        <span className="description">
          {date
            ? format(new Date(date), 'd LLL Y', {
                locale: fr,
              })
            : t('notSpecified')}
        </span>
        <span className="description">{location}</span>
        <span className="description">
          {date
            ? format(new Date(date), 'HH:mm', {
                locale: fr,
              })
            : t('notSpecified')}
        </span>
        {/* eslint-enable indent */}
      </Container>
      <Container
        className="view-more"
        onClick={() => {
          navigate(
            `/product/${productQualifierByCategory(category.key).key}/${id}`,
            {
              state: { date: context.selectedDate },
            },
          );
        }}
      >
        {t('view')}
      </Container>
    </Container>
  );
}

EventCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  date: PropTypes.string,
  location: PropTypes.string.isRequired,
};

EventCard.defaultProps = {
  date: undefined,
};

export default EventCard;
